import "./SpinnerButton.css";
import * as React from "react";

const SpinnerButton = () => {
  return (
    <button className="filter-button" disabled>
      <div className="loader-button" />
    </button>
  );
};

export default SpinnerButton;
