import * as React from "react";
import "./Legend.css";

const Legend = (props: any) => {
  const legendUrlLayer = props.legendUrlLayer;
  const displayLayer = props.displayLayer;

  return (
    displayLayer &&
    legendUrlLayer && (
      <img className="legend-img" src={legendUrlLayer} alt="Legend" />
    )
  );
};

export default Legend;
