import { api } from "../config/api";
import StatsCountryDto from "../dto/stats-country-dto";
import LayerVersionsDto from "../dto/layer-versions-dto";
import { Auth } from "aws-amplify";
import VersionWithMissingElementsDto from "../dto/versions-with-missing-elements-dto";
import EmailDto from "../dto/email-dto";

let authorizationHeaderValue: string;

async function getCurrentSession(): Promise<void> {
  const user = await Auth.currentSession();
  authorizationHeaderValue = user.getIdToken().getJwtToken();
}

async function ensureAuthorizationHeaderValue(): Promise<void> {
  if (!authorizationHeaderValue) {
    await getCurrentSession();
  }
}

export async function getCompleteVersions(): Promise<string[]> {
  const response = await api.request({
    url: "/versions",
    method: "GET",
  });
  return response.data;
}

export async function getAllVersions(): Promise<
  VersionWithMissingElementsDto[]
> {
  await ensureAuthorizationHeaderValue();
  const response = await api.request({
    url: "/versions/all",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authorizationHeaderValue,
      Accept: "application/json",
    },
  });
  return response.data;
}

export async function generateUploadFileLink(
  type: string,
  version: string
): Promise<string> {
  await ensureAuthorizationHeaderValue();
  const response = await api.request({
    url: `/file/${type}/post/url?version=${encodeURIComponent(version)}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authorizationHeaderValue,
      Accept: "application/json",
    },
  });

  return response.data;
}

export async function generateDownloadFileLink(
  type: string,
  version: string
): Promise<string> {
  await ensureAuthorizationHeaderValue();
  const response = await api.request({
    url: `/file/${type}/get/url?version=${encodeURIComponent(version)}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authorizationHeaderValue,
      Accept: "application/json",
    },
  });

  return response.data;
}

export async function deleteVersion(version: string): Promise<string> {
  await ensureAuthorizationHeaderValue();
  const response = await api.request({
    url: `/versions?version=${encodeURIComponent(version)}`,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: authorizationHeaderValue,
      Accept: "application/json",
    },
  });

  return response.data;
}

export async function getStatsCountry(): Promise<StatsCountryDto[]> {
  await ensureAuthorizationHeaderValue();
  const response = await api.request({
    url: `/stats-country`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authorizationHeaderValue,
      Accept: "application/json",
    },
  });

  return response.data;
}

export async function generatePartOfGridForCoordinates(
  west: number,
  east: number,
  south: number,
  north: number,
  version: string
): Promise<string> {
  await ensureAuthorizationHeaderValue();
  const response = await api.request({
    url: `/file/partial-grid?west=${encodeURIComponent(
      west
    )}&east=${encodeURIComponent(east)}&south=${encodeURIComponent(
      south
    )}&north=${encodeURIComponent(north)}&version=${encodeURIComponent(
      version
    )}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authorizationHeaderValue,
      Accept: "application/json",
    },
  });

  return response.data;
}

export async function generatePartOfGridForIndex(
  index: number,
  version: string
): Promise<string> {
  await ensureAuthorizationHeaderValue();
  const response = await api.request({
    url: `/file/partial-grid?index=${encodeURIComponent(
      index
    )}&version=${encodeURIComponent(version)}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authorizationHeaderValue,
      Accept: "application/json",
    },
  });

  return response.data;
}

export async function sendEmail(
  title: string,
  content: string
): Promise<string> {
  await ensureAuthorizationHeaderValue();
  const response = await api.request({
    url: "/email",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authorizationHeaderValue,
      Accept: "application/json",
    },
    params: {
      title: title,
      content: content,
    },
  });
  return response.data;
}

export async function retrieveLayerLinks(): Promise<LayerVersionsDto[]> {
  const response = await api.request({
    url: "/file/layer",
    method: "GET",
  });

  return response.data;
}
