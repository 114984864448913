import * as React from 'react';
import './User.css'
import { useAuth } from '../../hooks/use-auth';
import Login from '../login/Login';
import UserPanel from '../userpanel/UserPanel';

const User = () => {

    const auth = useAuth();
    if(!auth.isAuthenticated) {
        return <Login/>;
    }
    return <UserPanel/>
}

export default User;
