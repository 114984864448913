import * as React from "react";
import { useState } from "react";
import "./UserPanel.css";
import { useAuth } from "../../hooks/use-auth";
import LoadingButton from "../loadingbutton/LoadingButton";
import AdminUserCountries from "../adminusercountries/AdminUserCountries";
import AdminVersionList from "../adminversionlist/AdminVersionList";
import AdminUserUploadVersion from "../adminuseruploadversion/AdminUserUploadVersion";
import AdminSendEmail from "../adminsendemail/AdminSendEmail";

const UserPanel = () => {
  const auth = useAuth();

  const [disconnectIsLoading, setDisconnectIsLoading] = useState(false);
  const [tab, setTab] = useState("COMPLETE_VERSIONS");
  const [errorMessage, setErrorMessage] = useState("");
  const executeDisconnect = async () => {
    setDisconnectIsLoading(true);
    const result = await auth.signOut();
    if (!result.success) {
      setErrorMessage("Error while disconnecting.");
    }
    setDisconnectIsLoading(false);
  };

  return (
    <div className="user-panel-container">
      <span className="title">Account</span>
      <div className="button-container">
        <LoadingButton
          isLoading={disconnectIsLoading}
          onClick={executeDisconnect}
        >
          Disconnect
        </LoadingButton>
      </div>
      {errorMessage != "" && (
        <span className="message-error">{errorMessage}</span>
      )}
      {auth.isAdmin && (
        <div className="admin-panel-container">
          <span className="title">Admin</span>
          <div className="admin-panel-tab-container">
            <div
              onClick={() => setTab("COMPLETE_VERSIONS")}
              className={tab == "COMPLETE_VERSIONS" ? "tab-selected" : "tab"}
            >
              Complete versions
            </div>
            <div
              onClick={() => setTab("UPLOAD_VERSION")}
              className={tab == "UPLOAD_VERSION" ? "tab-selected" : "tab"}
            >
              Upload version
            </div>
            <div
              onClick={() => setTab("USERS_COUNTRIES")}
              className={tab == "USERS_COUNTRIES" ? "tab-selected" : "tab"}
            >
              Users countries
            </div>
            <div
              onClick={() => setTab("SEND_EMAIL")}
              className={tab == "SEND_EMAIL" ? "tab-selected" : "tab"}
            >
              Send email
            </div>
          </div>
          {tab == "COMPLETE_VERSIONS" && <AdminVersionList />}
          {tab == "UPLOAD_VERSION" && <AdminUserUploadVersion />}
          {tab == "USERS_COUNTRIES" && <AdminUserCountries />}
          {tab == "SEND_EMAIL" && <AdminSendEmail />}
        </div>
      )}
    </div>
  );
};
export default UserPanel;
