import * as React from "react";
import { useState } from "react";
import "./ForgottenPassword.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/use-auth";
import LoadingButton from "../loadingbutton/LoadingButton";

const ForgottenPassword = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [mailReinitialized, setMailReinitialized] = useState(false);
  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [reinitializeIsLoading, setReinitializeIsLoading] = useState(false);
  const [validateIsLoading, setValidateIsLoading] = useState(false);
  const [changePasswordErrorMessage, setChangePasswordErrorMessage] =
    useState("");
  const [sendPasswordErrorMessage, setSendPasswordErrorMessage] = useState("");

  const startPasswordReinitialization = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setValidateIsLoading(true);
    const result = await auth.reinitializePassword(username);
    if (result.success) {
      setMailReinitialized(true);
    } else {
      setSendPasswordErrorMessage(result.message);
    }
    setValidateIsLoading(false);
  };
  const executePasswordReinitialization = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setReinitializeIsLoading(true);
    const result = await auth.reinitializePasswordSubmit(
      username,
      code,
      password
    );
    if (result.success) {
      await auth.signIn(username, password);
      navigate("/");
    } else {
      setChangePasswordErrorMessage(result.message);
    }
    setReinitializeIsLoading(false);
  };

  return mailReinitialized ? (
    <div className="forgotten-password-change-password-container">
      <span className="forgotten-password-alone-item title">
        Forgotten password
      </span>
      <label className="label" htmlFor="code">
        Validation code:
      </label>
      <input
        className="input"
        type="text"
        id="code"
        value={code}
        onChange={(e) => setCode(e.target.value)}
      />
      <label className="label" htmlFor="password">
        Password:
      </label>
      <input
        className="input"
        type="password"
        id="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <div className="forgotten-password-alone-item">
        <LoadingButton
          isLoading={reinitializeIsLoading}
          disabled={password == "" || code == ""}
          onClick={executePasswordReinitialization}
        >
          Reinitialize
        </LoadingButton>
      </div>
      <div className="forgotten-password-alone-item">
        {changePasswordErrorMessage != "" && (
          <span className="message-error">{changePasswordErrorMessage}</span>
        )}
      </div>
    </div>
  ) : (
    <div className="forgotten-password-send-container">
      <span className="forgotten-password-alone-item title">
        Forgotten password
      </span>
      <label className="label" htmlFor="email">
        Email:
      </label>
      <input
        className="input"
        type="email"
        id="email"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <div className="forgotten-password-alone-item">
        <LoadingButton
          isLoading={validateIsLoading}
          disabled={username == ""}
          onClick={startPasswordReinitialization}
        >
          Reinitialize
        </LoadingButton>
      </div>
      <div className="forgotten-password-alone-item">
        {sendPasswordErrorMessage != "" && (
          <span className="message-error">{sendPasswordErrorMessage}</span>
        )}
      </div>
    </div>
  );
};
export default ForgottenPassword;
