import * as React from "react";
import { useEffect, useState } from "react";
import "./FilterBar.css";
import NumberInput from "../numberinput/NumberInput";
import { LatLng, LatLngBounds } from "leaflet";
import { useAuth } from "../../hooks/use-auth";
import { useNavigate } from "react-router-dom";
import { retrieveLayerLinks } from "../../service/api-service";
import LayerVersionsDto from "../../dto/layer-versions-dto";
// @ts-ignore
import { ReactComponent as LayersClear } from "../../icon/layers_clear.svg";
// @ts-ignore
import { ReactComponent as Layers } from "../../icon/layers.svg";
// @ts-ignore
import { ReactComponent as Select } from "../../icon/select.svg";
// @ts-ignore
import { ReactComponent as Deselect } from "../../icon/deselect.svg";
// @ts-ignore
import { ReactComponent as Restart } from "../../icon/restart.svg";
// @ts-ignore
import { ReactComponent as Download } from "../../icon/download.svg";
// @ts-ignore
import { ReactComponent as UnDownload } from "../../icon/undownload.svg";
import SpinnerButton from "../spinnerbutton/SpinnerButton";

const FilterBar = (props: any) => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [index, setIndex] = [props.index, props.setIndex];
  const [layerType, setLayerType] = [props.layerType, props.setLayerType];
  const rectangleBound: LatLngBounds = props.rectangleBound;
  const setRectangleBound = props.setRectangleBound;
  const displayLayer = props.displayLayer;
  const setDisplayLayer = props.setDisplayLayer;
  const displaySelect = props.displaySelect;
  const setDisplaySelect = props.setDisplaySelect;
  const displayDownloadPanel = props.displayDownloadPanel;
  const setDisplayDownloadPanel = props.setDisplayDownloadPanel;
  const setUrlLayer = props.setUrlLayer;
  const completeVersions = props.completeVersions;
  const setLegendUrlLayer = props.setLegendUrlLayer;
  const [validWest, setValidWest] = [props.validWest, props.setValidWest];
  const [validEast, setValidEast] = [props.validEast, props.setValidEast];
  const [validSouth, setValidSouth] = [props.validSouth, props.setValidSouth];
  const [validNorth, setValidNorth] = [props.validNorth, props.setValidNorth];

  const [layerVersions, setLayerVersions] = useState<LayerVersionsDto[]>([]);
  const [version, setVersion] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [failing, setFailing] = useState<boolean>(false);
  const [west, setWest] = useState<number>(-20);
  const [east, setEast] = useState<number>(20);
  const [south, setSouth] = useState<number>(-10);
  const [north, setNorth] = useState<number>(10);

  useEffect(() => {
    setDisplaySelect(false);
    setDisplayDownloadPanel(false);
  }, [auth.isAuthenticated]);

  useEffect(() => {
    startRetrieveLayerLinks();
  }, []);

  useEffect(() => {
    if (completeVersions.length > 0) setVersion(completeVersions[0]);
  }, [completeVersions]);

  useEffect(() => {
    const layerVersion = findLayerVersionByVersion(version);
    if (layerVersion) {
      if (layerType == "ANOMALY_LAYER") {
        setUrlLayer(layerVersion.anomalyLink);
        setLegendUrlLayer(layerVersion.anomalyLegendLink);
      }
      if (layerType == "INDEX_LAYER") {
        setUrlLayer(layerVersion.indexLink);
        setLegendUrlLayer(layerVersion.indexLegendLink);
      }
    }
  }, [layerType, version, layerVersions]);

  const startRetrieveLayerLinks = () => {
    retrieveLayerLinks()
      .then((result) => {
        setLayerVersions(result);
        setFailing(false);
        setLoading(false);
      })
      .catch(() => {
        setFailing(true);
        setLoading(false);
      });
  };

  const changeIndex = (v: any) => {
    if (v.target.valueAsNumber < 1) {
      v.target.valueAsNumber = 1;
    } else {
      setIndex(v.target.valueAsNumber);
    }
  };

  const changeWest = (v: any) => {
    setWest(v.target.valueAsNumber);
    if (
      v.target.valueAsNumber < -180 ||
      v.target.valueAsNumber > 180 ||
      v.target.valueAsNumber >= east
    ) {
      setValidWest(false);
      if (v.target.valueAsNumber >= east) setValidEast(false);
    } else {
      setValidWest(true);
      if (east < -180 || east > 180) {
        setValidEast(false);
      } else {
        setValidEast(true);
        setRectangleBound(
          new LatLngBounds(
            new LatLng(south, v.target.valueAsNumber),
            new LatLng(north, east)
          )
        );
      }
    }
  };

  const changeEast = (v: any) => {
    setEast(v.target.valueAsNumber);
    if (
      v.target.valueAsNumber < -180 ||
      v.target.valueAsNumber > 180 ||
      v.target.valueAsNumber <= west
    ) {
      setValidEast(false);
      if (v.target.valueAsNumber <= west) setValidWest(false);
    } else {
      setValidEast(true);
      if (west < -180 || west > 180) {
        setValidWest(false);
      } else {
        setValidWest(true);
        setRectangleBound(
          new LatLngBounds(
            new LatLng(south, west),
            new LatLng(north, v.target.valueAsNumber)
          )
        );
      }
    }
  };

  const changeSouth = (v: any) => {
    setSouth(v.target.valueAsNumber);
    if (
      v.target.valueAsNumber < -90 ||
      v.target.valueAsNumber > 90 ||
      v.target.valueAsNumber >= north
    ) {
      setValidSouth(false);
      if (v.target.valueAsNumber >= north) setValidNorth(false);
    } else {
      setValidSouth(true);
      if (north < -90 || north > 90) {
        setValidNorth(false);
      } else {
        setValidNorth(true);
        setRectangleBound(
          new LatLngBounds(
            new LatLng(v.target.valueAsNumber, west),
            new LatLng(north, east)
          )
        );
      }
    }
  };
  const changeNorth = (v: any) => {
    setNorth(v.target.valueAsNumber);
    if (
      v.target.valueAsNumber < -90 ||
      v.target.valueAsNumber > 90 ||
      v.target.valueAsNumber <= south
    ) {
      setValidNorth(false);
      if (v.target.valueAsNumber <= south) setValidSouth(false);
    } else {
      setValidNorth(true);
      if (south < -90 || south > 90) {
        setValidSouth(false);
      } else {
        setValidSouth(true);
        setRectangleBound(
          new LatLngBounds(
            new LatLng(south, west),
            new LatLng(v.target.valueAsNumber, east)
          )
        );
      }
    }
  };

  const onClickDisplaySelect = () => {
    if (auth.isAuthenticated) setDisplaySelect((prevDeps: any) => !prevDeps);
    else navigate("/user");
  };

  const onClickDisplayDownloadPanel = () => {
    if (auth.isAuthenticated)
      setDisplayDownloadPanel((prevDeps: any) => !prevDeps);
    else navigate("/user");
  };

  const findLayerVersionByVersion = (version: string) => {
    return layerVersions.find(
      (layerVersion) => layerVersion.version == version
    );
  };

  const working = () => {
    return !failing && !loading;
  };

  const handleDisplayLayerClick = () => {
    working() && setDisplayLayer((prevDeps: any) => !prevDeps);
    failing && !loading && startRetrieveLayerLinks();
  };

  return (
    <div className="filter-bar">
      {displaySelect && layerType == "ANOMALY_LAYER" && (
        <div className="select-area-anomaly">
          <NumberInput
            label="West"
            value={rectangleBound.getWest()}
            min="-180"
            max="180"
            valid={validWest}
            onChange={changeWest}
          ></NumberInput>
          <NumberInput
            label="East"
            value={rectangleBound.getEast()}
            min="-180"
            max="180"
            valid={validEast}
            onChange={changeEast}
          ></NumberInput>
          <NumberInput
            label="South"
            value={rectangleBound.getSouth()}
            min="-90"
            max="90"
            valid={validSouth}
            onChange={changeSouth}
          ></NumberInput>
          <NumberInput
            label="North"
            value={rectangleBound.getNorth()}
            min="-90"
            max="90"
            valid={validNorth}
            onChange={changeNorth}
          ></NumberInput>
        </div>
      )}
      {displaySelect && layerType == "INDEX_LAYER" && (
        <div className="select-area-index">
          <NumberInput
            label="Index"
            value={index}
            min="1"
            valid={true}
            onChange={changeIndex}
          ></NumberInput>
        </div>
      )}
      <div className="filter-area">
        {!loading && (
          <button
            title={working() && displayLayer ? "Remove layer" : "Display layer"}
            className={
              working() && displayLayer
                ? "filter-button active"
                : "filter-button"
            }
            onClick={handleDisplayLayerClick}
          >
            {working() && displayLayer && <LayersClear className="svg" />}
            {working() && !displayLayer && <Layers className="svg" />}
            {failing && !loading && <Restart className="svg" />}
          </button>
        )}
        {loading && <SpinnerButton />}
        <select
          title="Version"
          className="filter-select"
          value={version}
          onChange={(e) => setVersion(e.target.value)}
          disabled={completeVersions.length == 0 || loading}
        >
          {completeVersions.length > 0 &&
            completeVersions.map((completeVersion: string) => (
              <option className="filter-option" value={completeVersion}>
                {completeVersion}
              </option>
            ))}
        </select>
        <select
          title={"Type"}
          className="filter-select-long"
          value={layerType}
          onChange={(e) => setLayerType(e.target.value)}
          disabled={completeVersions.length == 0 || loading}
        >
          <option className="filter-option" value="ANOMALY_LAYER">
            Anomaly
          </option>
          <option className="filter-option" value="INDEX_LAYER">
            Index
          </option>
        </select>
        <button
          title={
            displaySelect
              ? "Remove part grid selector"
              : "Display part grid selector"
          }
          className={displaySelect ? "filter-button active" : "filter-button"}
          onClick={() => onClickDisplaySelect()}
          disabled={completeVersions.length == 0 || loading}
        >
          {!displaySelect && <Select className="svg" />}
          {displaySelect && <Deselect className="svg" />}
        </button>
        <button
          title={
            displayDownloadPanel
              ? "Remove download panel"
              : "Display download panel"
          }
          className={
            displayDownloadPanel ? "filter-button active" : "filter-button"
          }
          onClick={() => onClickDisplayDownloadPanel()}
          disabled={completeVersions.length == 0 || loading}
        >
          {!displayDownloadPanel && <Download className="svg" />}
          {displayDownloadPanel && <UnDownload className="svg" />}
        </button>
      </div>
    </div>
  );
};

export default FilterBar;
