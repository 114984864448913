import * as React from "react";
import "./MenuBar.css";
import { Link } from "react-router-dom";
// @ts-ignore
import { ReactComponent as Person } from "../../icon/person.svg";
// @ts-ignore
import { ReactComponent as Information } from "../../icon/information.svg";
// @ts-ignore
import { ReactComponent as Email } from "../../icon/email.svg";

const MenuBar = () => {
  return (
    <div className="menu-container">
      <Link to="/information">
        <button className="menu-button" title="Informations">
          <Information className="svg" />
        </button>
      </Link>
      <Link to="/user">
        <button className="menu-button" title="User panel">
          <Person className="svg" />
        </button>
      </Link>
      <a href="mailto:wdmam0@gmail.com">
        <button className="menu-button" title="Contact us">
          <Email className="svg" />
        </button>
      </a>
    </div>
  );
};

export default MenuBar;
