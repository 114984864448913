import * as React from 'react';
import './Router.css'
import ForgottenPassword from '../forgottenpassword/ForgottenPassword';
import VerifyAccount from '../verifyaccount/VerifyAccount';
import Subscribe from '../subscribe/Subscribe';
import { Route, Routes } from 'react-router-dom';
import Information from '../information/Information';
import PopUp from '../popup/PopUp';
import User from '../user/User';

const Router = () => {
    return <Routes>
            <Route path="/">
                <Route index/>
                <Route path="subscribe" element={
                    <PopUp><Subscribe/></PopUp>
                } />
                <Route
                    path="forgotten-password"
                    element={
                        <PopUp><ForgottenPassword/></PopUp>
                }/>
                <Route path="verify-account" element={
                    <PopUp><VerifyAccount/></PopUp>
                }/>
                <Route path="information" element={
                    <PopUp><Information/></PopUp>
                }/>
                <Route path="user" element={
                    <PopUp><User/></PopUp>
                }/>
            </Route>
        </Routes>
}
export default Router;
