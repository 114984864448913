import * as React from "react";
import { useState } from "react";
import { sendEmail } from "../../service/api-service";
import LoadingButton from "../loadingbutton/LoadingButton";
import "./AdminSendEmail.css";
import EmailDto from "../../dto/email-dto";

const AdminSendEmail = () => {
  const [emailTitle, setEmailTitle] = useState("");
  const [emailContent, setEmailContent] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const sendEmailAfterCheck = () => {
    setSuccessMessage("");
    setErrorMessage("");
    if (
      confirm("Are you sure you want to send this email to all WDMAM users?")
    ) {
      setLoading(true);
      sendEmail(emailTitle, emailContent)
        .then((response) => {
          setSuccessMessage("Email sent");
          setEmailTitle("");
          setEmailContent("");
        })
        .catch((err) => setErrorMessage("Error while sending email."))
        .finally(() => setLoading(false));
    }
  };
  return (
    <div className="admin-email-container">
      <div className="admin-title-container">
        <label className="label" htmlFor="emailTitle">
          Title:
        </label>
        <input
          className="large-input"
          id="emailTitle"
          type="text"
          onChange={(e) => setEmailTitle(e.target.value)}
          value={emailTitle}
        />
      </div>
      <div className="admin-title-container">
        <label className="label" htmlFor="emailContent">
          Email:
        </label>
        <textarea
          className="long-large-input"
          id="emailContent"
          value={emailContent}
          onChange={(e) => setEmailContent(e.target.value)}
        />
      </div>
      <div className="button-container">
        <LoadingButton
          isLoading={loading}
          disabled={emailTitle === "" || emailContent === "" || loading}
          onClick={sendEmailAfterCheck}
        >
          Send Email
        </LoadingButton>
      </div>
      {(successMessage !== "" || errorMessage !== "") && (
        <div>
          {successMessage !== "" && (
            <span className="message-success">{successMessage}</span>
          )}
          {errorMessage !== "" && (
            <span className="message-error">{errorMessage}</span>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminSendEmail;
