import * as React from "react";
import { useEffect, useState } from "react";
import { deleteVersion, getAllVersions } from "../../service/api-service";
import "./AdminVersionList.css";
import VersionWithMissingElementsDto from "../../dto/versions-with-missing-elements-dto";
// @ts-ignore
import { ReactComponent as Loader } from "../../icon/loader.svg";
import Spinner from "../spinner/Spinner";

const AdminVersionList = () => {
  const [completeVersions, setCompleteVersions] = useState<
    VersionWithMissingElementsDto[]
  >([]);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState("");
  const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [getAllVersionsErrorMessage, setGetAllVersionsErrorMessage] =
    useState("");

  useEffect(() => {
    refreshAllVersions();
  }, []);

  const refreshAllVersions = () => {
    getAllVersions()
      .then((result) => {
        setCompleteVersions(result);
        setLoading(false);
      })
      .catch(() =>
        setGetAllVersionsErrorMessage("Error while getting versions.")
      );
  };

  const deleteSelectedVersion = (version: string) => {
    deleteVersion(version)
      .then(() => {
        setDeleteSuccessMessage(`Version: ${version} was deleted.`);
        refreshAllVersions();
      })
      .catch((error) =>
        setDeleteErrorMessage(
          `Error while deleting ${version}. Error message= ${error}`
        )
      );
  };

  const createMissingFilesInformationContent = (missingFiles: string[]) => {
    return missingFiles.reduce(
      (accumulator, value) => `${accumulator}\n-${value}`,
      "Missing files:"
    );
  };

  return (
    <div className="admin-version-list-container">
      {loading && (
        <div className="spinner-container">
          <Spinner />
        </div>
      )}
      {!loading && getAllVersionsErrorMessage == "" && (
        <div>
          <table className="admin-version-list-content">
            <thead>
              <tr>
                <th colSpan={3}>Version name</th>
              </tr>
            </thead>
            <tbody className="admin-version-list-version-list-container">
              {completeVersions.map((versionWithMissingElementsDto) => {
                return (
                  <tr className="admin-version-list-version-container">
                    <td className="admin-version-list-version-value">
                      {versionWithMissingElementsDto.version}
                    </td>
                    <td
                      className="admin-version-list-version-value admin-version-list-warning-information"
                      title={createMissingFilesInformationContent(
                        versionWithMissingElementsDto.missingElements
                      )}
                    >
                      {versionWithMissingElementsDto.missingElements.length >
                        0 && "!"}
                    </td>
                    <td
                      className="admin-version-list-delete-button admin-version-list-version-value"
                      onClick={(event) => {
                        event.preventDefault();
                        if (
                          confirm(
                            `Are you sure you want to delete: version:${versionWithMissingElementsDto.version}?`
                          )
                        ) {
                          setLoading(true);
                          deleteSelectedVersion(
                            versionWithMissingElementsDto.version
                          );
                        }
                      }}
                    >
                      &times;
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <span className="message-success">{deleteSuccessMessage}</span>
          <span className="message-error">{deleteErrorMessage}</span>
        </div>
      )}
      {getAllVersionsErrorMessage != "" && (
        <span className="message-error">{getAllVersionsErrorMessage}</span>
      )}
    </div>
  );
};
export default AdminVersionList;
