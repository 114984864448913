export const AwsConfigAuth = {
  region: "eu-west-3",
  userPoolId: "eu-west-3_uoaVA5PzT",
  userPoolWebClientId: "4n2saoktd770n5lb7offdcfgfv",
  cookieStorage: {
    domain: "wdmam.org",
    path: "/",
    expires: 365,
    sameSite: "strict",
    secure: true,
  },
  authenticationFlowType: "USER_SRP_AUTH",
};
