import * as React from 'react';
import './AreaSelect.css'
import { Rectangle } from 'react-leaflet';

const AreaSelect = (props: any) => {
    const rectangleBound = props.rectangleBound;

    return <Rectangle bounds={rectangleBound} interactive={true}></Rectangle>;
}
export default AreaSelect;
