import * as React from "react";
import { Link } from "react-router-dom";
import "../popup/PopUp.css";

const PopUp = (props: any) => {
  const children = props.children;

  return (
    <div className="outer-popup">
      <div className="popup">
        <Link className="popup-close" to="/">
          &times;
        </Link>
        <div className="popup-content">{children}</div>
      </div>
    </div>
  );
};

export default PopUp;
