import * as React from "react";
import { useEffect, useState } from "react";
import {
  MapContainer,
  ScaleControl,
  TileLayer,
  ZoomControl,
} from "react-leaflet";
import { LatLng, LatLngBounds } from "leaflet";
import "./Map.css";
import Router from "../router/Router";
import WdmamLayer from "../wdmamlayer/WdmamLayer";
import AreaSelect from "../areaselect/AreaSelect";
import { BrowserRouter, Outlet } from "react-router-dom";
import { getCompleteVersions } from "../../service/api-service";
import Menu from "../menu/Menu";

export const Map = () => {
  const [urlLayer, setUrlLayer] = useState("");
  const [displayLayer, setDisplayLayer] = useState(true);
  const [displaySelect, setDisplaySelect] = useState(false);
  const [displayDownloadPanel, setDisplayDownloadPanel] = useState(false);
  const [rectangleBound, setRectangleBound] = useState(
    new LatLngBounds(new LatLng(-10, -20), new LatLng(10, 20))
  );
  const [index, setIndex] = useState(1);
  const [layerType, setLayerType] = useState("ANOMALY_LAYER");
  const [completeVersions, setCompleteVersions] = useState<string[]>([]);

  useEffect(() => {
    retrieveCompleteVersions();
  }, []);

  const retrieveCompleteVersions = () => {
    getCompleteVersions().then((versions: string[]) => {
      setCompleteVersions(versions);
    });
  };

  return (
    <BrowserRouter>
      <Router />
      <Outlet></Outlet>
      <Menu
        layerType={layerType}
        setLayerType={setLayerType}
        index={index}
        setIndex={setIndex}
        rectangleBound={rectangleBound}
        setRectangleBound={setRectangleBound}
        displayLayer={displayLayer}
        setDisplayLayer={setDisplayLayer}
        displaySelect={displaySelect}
        setDisplaySelect={setDisplaySelect}
        displayDownloadPanel={displayDownloadPanel}
        setDisplayDownloadPanel={setDisplayDownloadPanel}
        setUrlLayer={setUrlLayer}
        completeVersions={completeVersions}
      ></Menu>
      <MapContainer
        className="map"
        zoom={3}
        zoomControl={false}
        minZoom={3}
        maxZoom={6}
        center={[0, 0]}
        attributionControl={false}
        // Empeche de drag out des limites
        maxBoundsViscosity={1}
        maxBounds={new LatLngBounds(new LatLng(-90, -180), new LatLng(90, 180))}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <WdmamLayer displayLayer={displayLayer} urlLayer={urlLayer} />
        <ScaleControl position="bottomright" />
        {displaySelect && layerType == "ANOMALY_LAYER" && (
          <AreaSelect rectangleBound={rectangleBound} />
        )}
      </MapContainer>
    </BrowserRouter>
  );
};

export default Map;
