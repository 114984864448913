import * as React from "react";
import { useState } from "react";
import "./Subscribe.css";
import { CountryDropdown } from "react-country-region-selector";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/use-auth";
import LoadingButton from "../loadingbutton/LoadingButton";

const Subscribe = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const executeSubscribeRequest = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setIsLoading(true);
    const result = await auth.subscribe(username, password, name, country);
    if (result.success) {
      navigate("/verify-account", {
        state: { username: username, password: password, disable: true },
        replace: true,
      });
    } else {
      setErrorMessage(result.message);
    }
    setIsLoading(false);
  };

  return (
    <div className="subscribe-container">
      <span className="subscribe-alone-item title">Subscribe</span>
      <label className="label" htmlFor="email">
        Email:
      </label>
      <input
        className="input"
        type="email"
        id="email"
        onChange={(e) => setUsername(e.target.value)}
      />
      <label className="label" htmlFor="password">
        Password:
      </label>
      <input
        className="input"
        type="password"
        id="password"
        onChange={(e) => setPassword(e.target.value)}
      />
      <label className="label" htmlFor="name">
        Name:
      </label>
      <input
        className="input"
        type="text"
        id="name"
        onChange={(e) => setName(e.target.value)}
      />
      <label className="label" htmlFor="country">
        Country:
      </label>
      <CountryDropdown
        classes="select"
        value={country}
        id="country"
        onChange={(val) => setCountry(val)}
      />
      <Link className="subscribe-alone-item link" to="/user">
        Login
      </Link>
      <div className="subscribe-alone-item">
        <LoadingButton
          isLoading={isLoading}
          disabled={
            username == "" || password == "" || name == "" || country == ""
          }
          onClick={executeSubscribeRequest}
        >
          Submit
        </LoadingButton>
      </div>
      <div className="subscribe-alone-item">
        {errorMessage != "" && (
          <span className="message-error">{errorMessage}</span>
        )}
      </div>
    </div>
  );
};
export default Subscribe;
