import React, { useState } from "react";
import "./Menu.css";
import MainTitle from "../maintitle/MainTitle";
import MenuBar from "../menubar/MenuBar";
import Legend from "../legend/Legend";
import FilterBar from "../filterbar/FilterBar";
import DownloadPanel from "../downloadpanel/DownloadPanel";
import { LatLngBounds } from "leaflet";

const Menu = (props: any) => {
  const [index, setIndex] = [props.index, props.setIndex];
  const [layerType, setLayerType] = [props.layerType, props.setLayerType];
  const rectangleBound: LatLngBounds = props.rectangleBound;
  const setRectangleBound = props.setRectangleBound;
  const displayLayer = props.displayLayer;
  const setDisplayLayer = props.setDisplayLayer;
  const displaySelect = props.displaySelect;
  const setDisplaySelect = props.setDisplaySelect;
  const displayDownloadPanel = props.displayDownloadPanel;
  const setDisplayDownloadPanel = props.setDisplayDownloadPanel;
  const setUrlLayer = props.setUrlLayer;
  const completeVersions = props.completeVersions;
  const [legendUrlLayer, setLegendUrlLayer] = useState<string>("");
  const [validWest, setValidWest] = useState<boolean>(true);
  const [validEast, setValidEast] = useState<boolean>(true);
  const [validSouth, setValidSouth] = useState<boolean>(true);
  const [validNorth, setValidNorth] = useState<boolean>(true);

  return (
    <div className="menu">
      <div className="main-title-container">
        <MainTitle />
      </div>
      <div className="menu-bar-container">
        <MenuBar />
      </div>
      <div className="legend-container">
        <Legend displayLayer={displayLayer} legendUrlLayer={legendUrlLayer} />
      </div>
      <div className="filter-bar-container">
        <FilterBar
          className="filter-bar-menu"
          layerType={layerType}
          setLayerType={setLayerType}
          index={index}
          setIndex={setIndex}
          rectangleBound={rectangleBound}
          setRectangleBound={setRectangleBound}
          displayLayer={displayLayer}
          setDisplayLayer={setDisplayLayer}
          displaySelect={displaySelect}
          setDisplaySelect={setDisplaySelect}
          displayDownloadPanel={displayDownloadPanel}
          setDisplayDownloadPanel={setDisplayDownloadPanel}
          setUrlLayer={setUrlLayer}
          setLegendUrlLayer={setLegendUrlLayer}
          completeVersions={completeVersions}
          validWest={validWest}
          setValidWest={setValidWest}
          validEast={validEast}
          setValidEast={setValidEast}
          validSouth={validSouth}
          setValidSouth={setValidSouth}
          validNorth={validNorth}
          setValidNorth={setValidNorth}
        />
      </div>
      <div className="download-panel-container">
        <DownloadPanel
          displaySelect={displaySelect}
          displayDownloadPanel={displayDownloadPanel}
          setDisplayDownloadPanel={setDisplayDownloadPanel}
          rectangleBound={rectangleBound}
          layerType={layerType}
          index={index}
          completeVersions={completeVersions}
          validWest={validWest}
          validEast={validEast}
          validSouth={validSouth}
          validNorth={validNorth}
        />
      </div>
    </div>
  );
};

export default Menu;
