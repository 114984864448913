import * as React from "react";
import "./WdmamLayer.css";
import { ImageOverlay } from "react-leaflet";
import { LatLngBounds } from "leaflet";

export const WdmamLayer = (props: any) => {
  const urlLayer = props.urlLayer;
  const displayLayer = props.displayLayer;
  return (
    urlLayer != "" &&
    displayLayer && (
      <ImageOverlay
        url={urlLayer}
        opacity={1}
        zIndex={5}
        bounds={new LatLngBounds([-72, -180], [72, 180])}
      />
    )
  );
};

export default WdmamLayer;
