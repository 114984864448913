import * as React from "react";
import { useState } from "react";
import "./Login.css";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/use-auth";
import LoadingButton from "../loadingbutton/LoadingButton";

const Login = () => {
  const auth = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const executeSignIn = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const result = await auth.signIn(username, password);
    if (!result.success) {
      setErrorMessage(result.message);
    }
    setIsLoading(false);
  };

  return (
    <div className="login-container">
      <span className="login-alone-item title">Login</span>
      <label className="label" htmlFor="email">
        Email:
      </label>
      <input
        className="input"
        type="email"
        id="email"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <label className="label" htmlFor="password">
        Password:
      </label>
      <input
        className="input"
        type="password"
        id="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <div className="login-alone-item login-link-container">
        <Link className="link login-triple-item" to="/forgotten-password">
          Forgotten password
        </Link>
        <Link
          className="link login-triple-item"
          to="/verify-account"
          state={{
            state: { username: "", password: "", disabled: false },
          }}
        >
          Verify account
        </Link>
        <Link className="link login-triple-item" to="/subscribe">
          Subscribe
        </Link>
      </div>
      <div className="login-alone-item">
        <LoadingButton
          isLoading={isLoading}
          disabled={username == "" || password == ""}
          onClick={executeSignIn}
        >
          Login
        </LoadingButton>
      </div>
      <div className="login-alone-item">
        {errorMessage != "" && (
          <span className="message-error">{errorMessage}</span>
        )}
      </div>
    </div>
  );
};
export default Login;
