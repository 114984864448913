import "./LoadingButton.css";
import * as React from "react";
// @ts-ignore
import { ReactComponent as Loader } from "../../icon/loader.svg";

const LoadingButton = (props: any) => {
  const loading = props.isLoading;
  return (
    <button
      className="loading-button"
      disabled={props.disabled ? props.disabled || loading : loading}
      onClick={props.onClick}
    >
      {!loading ? props.children : <Loader className="spinner" />}
    </button>
  );
};

export default LoadingButton;
