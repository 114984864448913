import * as React from "react";
import { RefObject, useEffect, useRef, useState } from "react";
import LoadingButton from "../loadingbutton/LoadingButton";
import axios, { AxiosRequestConfig } from "axios";
import { generateUploadFileLink } from "../../service/api-service";
import "./AdminUserUploadVersion.css";

const AdminUserUploadVersion = () => {
  const [version, setVersion] = useState("");
  const [anomalyMapFile, setAnomalyMapFile] = useState<File | null>(null);
  const [indexMapFile, setIndexMapFile] = useState<File | null>(null);
  const [gridFile, setGridFile] = useState<File | null>(null);
  const [modelFile, setModelFile] = useState<File | null>(null);
  const [anomalyMapDescriptionFile, setAnomalyMapDescriptionFile] =
    useState<File | null>(null);
  const [indexMapDescriptionFile, setIndexMapDescriptionFile] =
    useState<File | null>(null);
  const [gridDescriptionFile, setGridDescriptionFile] = useState<File | null>(
    null
  );
  const [modelDescriptionFile, setModelDescriptionFile] = useState<File | null>(
    null
  );
  const [anomalyLayerFile, setAnomalyLayerFile] = useState<File | null>(null);
  const [anomalyLayerLegendFile, setAnomalyLayerLegendFile] =
    useState<File | null>(null);
  const [indexLayerFile, setIndexLayerFile] = useState<File | null>(null);
  const [indexLayerLegendFile, setIndexLayerLegendFile] = useState<File | null>(
    null
  );
  const [anomalyMapSuccessMessage, setAnomalyMapSuccessMessage] = useState("");
  const [indexMapSuccessMessage, setIndexMapSuccessMessage] = useState("");
  const [gridSuccessMessage, setGridSuccessMessage] = useState("");
  const [modelSuccessMessage, setModelSuccessMessage] = useState("");
  const [
    anomalyMapDescriptionSuccessMessage,
    setAnomalyMapDescriptionSuccessMessage,
  ] = useState("");
  const [
    indexMapDescriptionSuccessMessage,
    setIndexMapDescriptionSuccessMessage,
  ] = useState("");
  const [gridDescriptionSuccessMessage, setGridDescriptionSuccessMessage] =
    useState("");
  const [modelDescriptionSuccessMessage, setModelDescriptionSuccessMessage] =
    useState("");
  const [anomalyLayerSuccessMessage, setAnomalyLayerSuccessMessage] =
    useState("");
  const [
    anomalyLayerLegendSuccessMessage,
    setAnomalyLayerLegendSuccessMessage,
  ] = useState("");
  const [indexLayerSuccessMessage, setIndexLayerSuccessMessage] = useState("");
  const [indexLayerLegendSuccessMessage, setIndexLayerLegendSuccessMessage] =
    useState("");
  const [anomalyMapFailMessage, setAnomalyMapFailMessage] = useState("");
  const [indexMapFailMessage, setIndexMapFailMessage] = useState("");
  const [gridFailMessage, setGridFailMessage] = useState("");
  const [modelFailMessage, setModelFailMessage] = useState("");
  const [
    anomalyMapDescriptionFailMessage,
    setAnomalyMapDescriptionFailMessage,
  ] = useState("");
  const [indexMapDescriptionFailMessage, setIndexMapDescriptionFailMessage] =
    useState("");
  const [gridDescriptionFailMessage, setGridDescriptionFailMessage] =
    useState("");
  const [modelDescriptionFailMessage, setModelDescriptionFailMessage] =
    useState("");
  const [anomalyLayerFailMessage, setAnomalyLayerFailMessage] = useState("");
  const [anomalyLayerLegendFailMessage, setAnomalyLayerLegendFailMessage] =
    useState("");
  const [indexLayerFailMessage, setIndexLayerFailMessage] = useState("");
  const [indexLayerLegendFailMessage, setIndexLayerLegendFailMessage] =
    useState("");
  const [uploadIsLoading, setUploadIsLoading] = useState(false);
  const [anomalyMapLoading, setAnomalyMapLoading] = useState(false);
  const [indexMapLoading, setIndexMapLoading] = useState(false);
  const [gridLoading, setGridLoading] = useState(false);
  const [modelLoading, setModelLoading] = useState(false);
  const [anomalyMapDescriptionLoading, setAnomalyMapDescriptionLoading] =
    useState(false);
  const [indexMapDescriptionLoading, setIndexMapDescriptionLoading] =
    useState(false);
  const [gridDescriptionLoading, setGridDescriptionLoading] = useState(false);
  const [modelDescriptionLoading, setModelDescriptionLoading] = useState(false);
  const [anomalyLayerLoading, setAnomalyLayerLoading] = useState(false);
  const [anomalyLayerLegendLoading, setAnomalyLayerLegendLoading] =
    useState(false);
  const [indexLayerLoading, setIndexLayerLoading] = useState(false);
  const [indexLayerLegendLoading, setIndexLayerLegendLoading] = useState(false);
  const anomalyMapRef = useRef(null);
  const indexMapRef = useRef(null);
  const gridRef = useRef(null);
  const modelRef = useRef(null);
  const anomalyMapDescriptionRef = useRef(null);
  const indexMapDescriptionRef = useRef(null);
  const gridDescriptionRef = useRef(null);
  const modelDescriptionRef = useRef(null);
  const anomalyLayerRef = useRef(null);
  const indexLayerRef = useRef(null);
  const anomalyLayerLegendRef = useRef(null);
  const indexLayerLegendRef = useRef(null);

  useEffect(() => {
    if (
      !anomalyMapLoading &&
      !indexMapLoading &&
      !gridLoading &&
      !modelLoading &&
      !anomalyMapDescriptionLoading &&
      !indexMapDescriptionLoading &&
      !gridDescriptionLoading &&
      !modelDescriptionLoading &&
      !anomalyLayerLoading &&
      !anomalyLayerLegendLoading &&
      !indexLayerLoading &&
      !indexLayerLegendLoading
    )
      clearUploadFormAndStopLoading();
  }, [
    anomalyMapLoading,
    indexMapLoading,
    gridLoading,
    modelLoading,
    anomalyMapDescriptionLoading,
    indexMapDescriptionLoading,
    gridDescriptionLoading,
    modelDescriptionLoading,
    anomalyLayerLoading,
    anomalyLayerLegendLoading,
    indexLayerLoading,
    indexLayerLegendLoading,
  ]);

  const getContentTypeFromType = (type: string) => {
    switch (type) {
      case "ANOMALY_MAP":
      case "INDEX_MAP":
        return "image/jpeg";
      case "GRID":
        return "text/plain";
      case "MODEL":
        return "application/gzip";
      case "ANOMALY_MAP_DESCRIPTION":
      case "INDEX_MAP_DESCRIPTION":
      case "GRID_DESCRIPTION":
      case "MODEL_DESCRIPTION":
        return "text/html";
      case "ANOMALY_LAYER":
      case "ANOMALY_LAYER_LEGEND":
      case "INDEX_LAYER":
      case "INDEX_LAYER_LEGEND":
        return "image/png";
      default:
        return "";
    }
  };

  const createUploadRequest = (url: string, type: string, file: any) => {
    // @ts-ignore
    const axiosRequestConfig: AxiosRequestConfig = {
      url: url,
      headers: {
        "Content-Type": getContentTypeFromType(type),
        "Content-Disposition": "attachment",
      },
    };
    return axios.put(url, file, axiosRequestConfig);
  };

  const uploadFile = (
    type: string,
    setSuccessMessage: (a: string) => void,
    setErrorMessage: (a: string) => void,
    setLoading: (a: boolean) => void,
    file: File | null
  ) => {
    if (file == null) return;
    setLoading(true);
    generateUploadFileLink(type, version)
      .then((url) => {
        createUploadRequest(url, type, file)
          .then(() => {
            setErrorMessage("");
            setSuccessMessage(`${type} file is uploaded`);
            setLoading(false);
          })
          .catch((message: any) => {
            setSuccessMessage("");
            setErrorMessage(`${type} file fail to upload: ${message}`);
            setLoading(false);
          });
      })
      .catch((message) => {
        setSuccessMessage("");
        setErrorMessage(
          `Error while generating upload file for ${type} file: ${message}`
        );
        setLoading(false);
      });
  };

  const uploadVersion = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setUploadIsLoading(true);

    uploadFile(
      "ANOMALY_MAP",
      setAnomalyMapSuccessMessage,
      setAnomalyMapFailMessage,
      setAnomalyMapLoading,
      anomalyMapFile
    );
    uploadFile(
      "ANOMALY_MAP_DESCRIPTION",
      setAnomalyMapDescriptionSuccessMessage,
      setAnomalyMapDescriptionFailMessage,
      setAnomalyMapDescriptionLoading,
      anomalyMapDescriptionFile
    );
    uploadFile(
      "INDEX_MAP",
      setIndexMapSuccessMessage,
      setIndexMapFailMessage,
      setIndexMapLoading,
      indexMapFile
    );
    uploadFile(
      "INDEX_MAP_DESCRIPTION",
      setIndexMapDescriptionSuccessMessage,
      setIndexMapDescriptionFailMessage,
      setIndexMapDescriptionLoading,
      indexMapDescriptionFile
    );
    uploadFile(
      "GRID",
      setGridSuccessMessage,
      setGridFailMessage,
      setGridLoading,
      gridFile
    );
    uploadFile(
      "GRID_DESCRIPTION",
      setGridDescriptionSuccessMessage,
      setGridDescriptionFailMessage,
      setGridDescriptionLoading,
      gridDescriptionFile
    );
    uploadFile(
      "MODEL",
      setModelSuccessMessage,
      setModelFailMessage,
      setModelLoading,
      modelFile
    );
    uploadFile(
      "MODEL_DESCRIPTION",
      setModelDescriptionSuccessMessage,
      setModelDescriptionFailMessage,
      setModelDescriptionLoading,
      modelDescriptionFile
    );
    uploadFile(
      "ANOMALY_LAYER",
      setAnomalyLayerSuccessMessage,
      setAnomalyLayerFailMessage,
      setAnomalyLayerLoading,
      anomalyLayerFile
    );
    uploadFile(
      "ANOMALY_LAYER_LEGEND",
      setAnomalyLayerLegendSuccessMessage,
      setAnomalyLayerLegendFailMessage,
      setAnomalyLayerLegendLoading,
      anomalyLayerLegendFile
    );
    uploadFile(
      "INDEX_LAYER",
      setIndexLayerSuccessMessage,
      setIndexLayerFailMessage,
      setIndexLayerLoading,
      indexLayerFile
    );
    uploadFile(
      "INDEX_LAYER_LEGEND",
      setIndexLayerLegendSuccessMessage,
      setIndexLayerLegendFailMessage,
      setIndexLayerLegendLoading,
      indexLayerLegendFile
    );
  };

  const clearUploadFormAndStopLoading = () => {
    setUploadIsLoading(false);
    setVersion("");
    setAnomalyMapFile(null);
    setIndexMapFile(null);
    setGridFile(null);
    setModelFile(null);
    setAnomalyMapDescriptionFile(null);
    setIndexMapDescriptionFile(null);
    setGridDescriptionFile(null);
    setModelDescriptionFile(null);
    setAnomalyLayerFile(null);
    setAnomalyLayerLegendFile(null);
    setIndexLayerFile(null);
    setIndexLayerLegendFile(null);
    // @ts-ignore
    anomalyMapRef.current.value = null;
    // @ts-ignore
    indexMapRef.current.value = null;
    // @ts-ignore
    gridRef.current.value = null;
    // @ts-ignore
    modelRef.current.value = null;
    // @ts-ignore
    anomalyMapDescriptionRef.current.value = null;
    // @ts-ignore
    indexMapDescriptionRef.current.value = null;
    // @ts-ignore
    gridDescriptionRef.current.value = null;
    // @ts-ignore
    modelDescriptionRef.current.value = null;
    // @ts-ignore
    anomalyLayerRef.current.value = null;
    // @ts-ignore
    indexLayerRef.current.value = null;
    // @ts-ignore
    anomalyLayerLegendRef.current.value = null;
    // @ts-ignore
    indexLayerLegendRef.current.value = null;
  };

  const notCorrectVersion = () => {
    const regex = "^\\S+$";
    const match = version.match(regex);
    return !match;
  };

  const createSuccessMessage = (successMessage: string) => {
    return (
      successMessage != "" && (
        <tr className="admin-user-upload-file-container">
          <td
            colSpan={3}
            className="message-success admin-user-upload-file-value"
          >
            {successMessage}
          </td>
        </tr>
      )
    );
  };

  const createErrorMessage = (errorMessage: string) => {
    return (
      errorMessage != "" && (
        <tr className="admin-user-upload-file-container">
          <td
            colSpan={3}
            className="message-error admin-user-upload-file-value"
          >
            {errorMessage}
          </td>
        </tr>
      )
    );
  };

  const removeAllMessages = () => {
    setAnomalyMapSuccessMessage("");
    setIndexMapSuccessMessage("");
    setGridSuccessMessage("");
    setModelSuccessMessage("");
    setAnomalyMapDescriptionSuccessMessage("");
    setIndexMapDescriptionSuccessMessage("");
    setGridDescriptionSuccessMessage("");
    setModelDescriptionSuccessMessage("");
    setAnomalyLayerSuccessMessage("");
    setAnomalyLayerLegendSuccessMessage("");
    setIndexLayerSuccessMessage("");
    setIndexLayerLegendSuccessMessage("");
    setAnomalyMapFailMessage("");
    setIndexMapFailMessage("");
    setGridFailMessage("");
    setModelFailMessage("");
    setAnomalyMapDescriptionFailMessage("");
    setIndexMapDescriptionFailMessage("");
    setGridDescriptionFailMessage("");
    setModelDescriptionFailMessage("");
    setAnomalyLayerFailMessage("");
    setAnomalyLayerLegendFailMessage("");
    setIndexLayerFailMessage("");
    setIndexLayerLegendFailMessage("");
  };

  const createInputFile = (
    label: string,
    setFile: (a: File) => void,
    ref: RefObject<HTMLInputElement>,
    description: string
  ) => {
    return (
      <tr className="admin-user-upload-file-container">
        <td className="admin-user-upload-file-value">
          <label className="popup-label">{label}:</label>
        </td>
        <td className="admin-user-upload-file-value">
          <input
            className="popup-input"
            type="file"
            disabled={uploadIsLoading}
            ref={ref}
            onChange={(e) => {
              removeAllMessages();
              if (e.target.files != null && e.target.files.length > 0) {
                setFile(e.target.files[0]);
              }
            }}
          />
        </td>
        <td className="admin-user-upload-file-value">
          <span className="popup-label">{description}</span>
        </td>
      </tr>
    );
  };

  return (
    <div className="admin-user-upload-container">
      <div className="p">
        You have to set the version and at least one file to upload. You can
        replace existing file versions.
      </div>
      <div className="admin-user-upload-version-container">
        <label className="label">Version:</label>
        <input
          className="input"
          type="text"
          value={version}
          disabled={uploadIsLoading}
          onChange={(e) => {
            removeAllMessages();
            setVersion(e.target.value);
          }}
        />
      </div>
      <table>
        <tbody className="admin-user-upload-version-file-uploader">
          {createInputFile(
            "Anomaly map",
            setAnomalyMapFile,
            anomalyMapRef,
            "JPG format"
          )}
          {createSuccessMessage(anomalyMapSuccessMessage)}
          {createErrorMessage(anomalyMapFailMessage)}
          {createInputFile(
            "Anomaly map description",
            setAnomalyMapDescriptionFile,
            anomalyMapDescriptionRef,
            "HTML format"
          )}
          {createSuccessMessage(anomalyMapDescriptionSuccessMessage)}
          {createErrorMessage(anomalyMapDescriptionFailMessage)}
          {createInputFile(
            "Index map",
            setIndexMapFile,
            indexMapRef,
            "JPG format"
          )}
          {createSuccessMessage(indexMapSuccessMessage)}
          {createErrorMessage(indexMapFailMessage)}
          {createInputFile(
            "Index map description",
            setIndexMapDescriptionFile,
            indexMapDescriptionRef,
            "HTML format"
          )}
          {createSuccessMessage(indexMapDescriptionSuccessMessage)}
          {createErrorMessage(indexMapDescriptionFailMessage)}
          {createInputFile("Grid", setGridFile, gridRef, "Text format")}
          {createSuccessMessage(gridSuccessMessage)}
          {createErrorMessage(gridFailMessage)}
          {createInputFile(
            "Grid description",
            setGridDescriptionFile,
            gridDescriptionRef,
            "HTML format"
          )}
          {createSuccessMessage(gridDescriptionSuccessMessage)}
          {createErrorMessage(gridDescriptionFailMessage)}
          {createInputFile("Model", setModelFile, modelRef, "OUT.GZ format")}
          {createSuccessMessage(modelSuccessMessage)}
          {createErrorMessage(modelFailMessage)}
          {createInputFile(
            "Model description",
            setModelDescriptionFile,
            modelDescriptionRef,
            "HTML format"
          )}
          {createSuccessMessage(modelDescriptionSuccessMessage)}
          {createErrorMessage(modelDescriptionFailMessage)}
          {createInputFile(
            "Anomaly layer",
            setAnomalyLayerFile,
            anomalyLayerRef,
            "PNG format (Coordinates: (-190, 190),(-72, 72))"
          )}
          {createSuccessMessage(anomalyLayerSuccessMessage)}
          {createErrorMessage(anomalyLayerFailMessage)}
          {createInputFile(
            "Anomaly layer legend",
            setAnomalyLayerLegendFile,
            anomalyLayerLegendRef,
            "PNG format (vertical legend)"
          )}
          {createSuccessMessage(anomalyLayerLegendSuccessMessage)}
          {createErrorMessage(anomalyLayerLegendFailMessage)}
          {createInputFile(
            "Index layer",
            setIndexLayerFile,
            indexLayerRef,
            "PNG format (Coordinates: (-190, 190),(-72, 72))"
          )}
          {createSuccessMessage(indexLayerSuccessMessage)}
          {createErrorMessage(indexLayerFailMessage)}
          {createInputFile(
            "Index layer legend",
            setIndexLayerLegendFile,
            indexLayerLegendRef,
            "PNG format (vertical legend)"
          )}
          {createSuccessMessage(indexLayerLegendSuccessMessage)}
          {createErrorMessage(indexLayerLegendFailMessage)}
        </tbody>
      </table>
      <div className="button-container">
        <LoadingButton
          isLoading={uploadIsLoading}
          disabled={
            (anomalyMapFile == null &&
              indexMapFile == null &&
              gridFile == null &&
              modelFile == null &&
              anomalyMapDescriptionFile == null &&
              indexMapDescriptionFile == null &&
              gridDescriptionFile == null &&
              modelDescriptionFile == null &&
              anomalyLayerFile == null &&
              anomalyLayerLegendFile == null &&
              indexLayerFile == null &&
              indexLayerLegendFile == null) ||
            version == "" ||
            notCorrectVersion()
          }
          onClick={uploadVersion}
        >
          Upload
        </LoadingButton>
      </div>
    </div>
  );
};

export default AdminUserUploadVersion;
