import * as React from "react";
import {useState} from "react";
import "./VerifyAccount.css";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/use-auth";
import LoadingButton from "../loadingbutton/LoadingButton";
import {State} from "../../model/State";

const VerifyAccount = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const state = useLocation();
  const [usernameToVerify, setUsernameToVerify] = useState(
    state.state.username ? state.state.username : ""
  );
  const [passwordToVerify, setPasswordToVerify] = useState(
    state.state.password ? state.state.password : ""
  );
  const toDisable = state.state.disable ? state.state.disable : false;
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [verificationCodeState, setVerificationCodeState] = useState(State.UNDEFINED);
  const [verificationCodeLoading, setVerificationCodeLoading] = useState(false);

  const executeVerifyAccount = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setIsLoading(true);
    const subscribeResult = await auth.subscribeSubmit(usernameToVerify, code);
    const signInResult = await auth.signIn(usernameToVerify, passwordToVerify);
    if (!signInResult.success) {
      setErrorMessage(
        `Error during account verification:${subscribeResult.message}`
      );
      setIsLoading(false);
    } else {
        setIsLoading(false);
        navigate("/");
    }
  };

  const executeSendVerificationButton = async (

  ) => {
      setVerificationCodeLoading(true)
      const result = await auth.resendVerificationCode(usernameToVerify)
      setVerificationCodeLoading(false)
      if (!result.success) {
          setVerificationCodeState(State.FAIL)
      } else {
          setVerificationCodeState(State.SUCCESS)
      }
  }
  const displayVerificationCodeLink = () => {
      switch (verificationCodeState) {
          case State.SUCCESS:
              return <div className="verify-account-alone-item message-success">Success!</div>
          case State.FAIL:
              return <a className="verify-account-alone-item a message-error"
                        onClick={executeSendVerificationButton}>Error, resend verification button</a>
          default:
              return <a className="verify-account-alone-item a"
                        onClick={executeSendVerificationButton}>Resend verification button</a>;
      }
  }

      return (
          <div className="verify-account-container">
              <span className="title verify-account-alone-item">Verify Account</span>
              <label className="label" htmlFor="email">
        Email:
      </label>
      <input
        className="input"
        disabled={toDisable}
        type="email"
        id="email"
        value={usernameToVerify}
        onChange={(e) => setUsernameToVerify(e.target.value)}
      />
        {!verificationCodeLoading ? displayVerificationCodeLink()
         : <div className="verify-account-alone-item a-disabled">Sending verification button...</div>
        }
      <label className="label" htmlFor="password">
        Password:
      </label>
      <input
        className="input"
        disabled={toDisable}
        type="password"
        id="password"
        value={passwordToVerify}
        onChange={(e) => setPasswordToVerify(e.target.value)}
      />
      <label className="label" htmlFor="code">
        Code:
      </label>
      <input
        className="input"
        type="text"
        id="code"
        value={code}
        onChange={(e) => setCode(e.target.value)}
      />

      <Link className="link verify-account-alone-item" to="/user">
        Login
      </Link>
      <div className="verify-account-alone-item">
        <LoadingButton
          isLoading={isLoading}
          disabled={usernameToVerify == "" || passwordToVerify == "" || code == ""}
          onClick={executeVerifyAccount}
        >
          Verify Account
        </LoadingButton>
      </div>
      <div className="verify-account-alone-item">
        {errorMessage != "" && (
          <span className="message-error">{errorMessage}</span>
        )}
      </div>
    </div>
  );
};
export default VerifyAccount
