import { Amplify, Auth } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { AwsConfigAuth } from '../config/auth';

Amplify.configure({ Auth: AwsConfigAuth });

interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  isAdmin: Boolean;
  username: string;
  signIn: (username: string, password: string) => Promise<Result>;
  signOut: () => Promise<Result>;
  subscribe: (
      username: string,
      password: string,
      name: string,
      country: string
  ) => Promise<Result>;
  subscribeSubmit: (username: string, code: string) => Promise<Result>;
  reinitializePassword: (username: string) => Promise<Result>;
  reinitializePasswordSubmit: (
      username: string,
      code: string,
      password: string
  ) => Promise<Result>,
  resendVerificationCode: (
      username: string
  ) => Promise<Result>;
}

interface Result {
  success: boolean;
  message: string;
}

type Props = {
  children?: React.ReactNode;
};

const authContext = createContext({} as UseAuth);

export const ProvideAuth: React.FC<Props> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [username, setUsername] = useState("");

  useEffect(() => {
    Auth.currentAuthenticatedUser()
        .then((result) => {
          setUsername(result.username);
          setIsAuthenticated(true);
          setIsLoading(false);
          setIsAdmin(checkIfAdmin(result.attributes));
        })
        .catch(() => {
          setUsername("");
          setIsAuthenticated(false);
          setIsLoading(false);
          setIsAdmin(false);
        });
  }, []);

  const checkIfAdmin = (attributes: any) => {
    return attributes["custom:role"] === "admin";
  };

  const signIn = async (username: string, password: string) => {
    try {
      const result = await Auth.signIn(username, password);
      setUsername(result.username);
      setIsAuthenticated(true);
      setIsAdmin(checkIfAdmin(result.attributes));
      return { success: true, message: "" };
    } catch (error) {
      return {
        success: false,
        message: "LOGIN FAIL",
      };
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setUsername("");
      setIsAdmin(false)
      setIsAuthenticated(false);
      return { success: true, message: "" };
    } catch (error) {
      return {
        success: false,
        message: "Disconnect failed",
      };
    }
  };

  const subscribe = async (
      username: string,
      password: string,
      name: string,
      country: string
  ) => {
    try {
      await Auth.signUp({
        username: username,
        password: password,
        attributes: {
          "custom:country": country,
          "custom:fullName": name,
        },
      });
      return { success: true, message: "" };
    } catch (error) {
      return {
        success: false,
        message: "" + error,
      };
    }
  };

  const subscribeSubmit = async (username: string, code: string) => {
    const result = await Auth.confirmSignUp(username, code);
    return { success: result.success, message: "" };
  };

  const reinitializePassword = async (username: string) => {
    try {
      await Auth.forgotPassword(username);
      return { success: true, message: "" };
    } catch (error) {
      return {
        success: false,
        message: "Password reinitialization fail",
      };
    }
  };

  const reinitializePasswordSubmit = async (
      username: string,
      code: string,
      password: string
  ) => {
    try {
      await Auth.forgotPasswordSubmit(username, code, password);
      return { success: true, message: "" };
    } catch (error) {
      return {
        success: false,
        message: "Password reinitialization fail",
      };
    }
  };

  const resendVerificationCode = async (
      username: string
  ) => {
    try {
      const result = await Auth.resendSignUp(username);
      return { success: true, message: "" };
    } catch (error) {
      return {
        success: false,
        message: `${error}`,
      }
    }
  }

  return {
    isLoading,
    isAuthenticated,
    isAdmin,
    username,
    signIn,
    signOut,
    subscribe,
    subscribeSubmit,
    reinitializePassword,
    reinitializePasswordSubmit,
    resendVerificationCode
  };
};
