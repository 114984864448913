import * as React from "react";
import "./NumberInput.css";

const NumberInput = (props: any) => {
  const label = props.label;
  const value = props.value;
  const min = props.min;
  const max = props.max;
  const valid = props.valid;
  const onChange = props.onChange;

  return (
    <div className="select-area-container">
      <label
        className={
          valid
            ? "select-area-label"
            : "select-area-label select-area-label-invalid"
        }
        htmlFor={label}
      >
        {label}
      </label>
      <input
        className={
          valid
            ? "select-area-input"
            : "select-area-input select-area-input-invalid"
        }
        name={label}
        type="number"
        defaultValue={value}
        min={min}
        max={max}
        onChange={onChange}
      />
    </div>
  );
};

export default NumberInput;
