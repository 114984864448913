import { Chart } from "react-google-charts";
import * as React from "react";
import { useEffect, useState } from "react";
import { getStatsCountry } from "../../service/api-service";
import Spinner from "../spinner/Spinner";

const AdminUserCountries = () => {
  const [loading, setLoading] = useState(true);
  const [statsCountryDto, setStatsCountryDto] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    getStatsCountry()
      .then((result) => {
        const googlePieChartMap = result
          .sort(
            (value1, value2) =>
              value2.numberOfSubscriber - value1.numberOfSubscriber
          )
          .map((statCountry) => [
            statCountry.country,
            statCountry.numberOfSubscriber,
          ]);
        const total = result
          .map((value) => value.numberOfSubscriber)
          .reduce((value1, value2) => value1 + value2, 0)
          .valueOf();

        // @ts-ignore
        setStatsCountryDto([
          ["Country", "Number of subscriber"],
          ["Total", total],
          ...googlePieChartMap,
        ]);
        setLoading(false);
      })
      .catch(() => setErrorMessage("Error while retrieving users countries."));
  }, []);

  return (
    <div className="admin-user-countries-container">
      {loading && (
        <div className="spinner-container">
          <Spinner />
        </div>
      )}
      {!loading && errorMessage == "" && (
        <div className="admin-user-countries-chart-container">
          <Chart
            chartType="BarChart"
            data={statsCountryDto}
            options={{
              title: "Numbers of users by countries",
              legend: { position: "none" },
            }}
            width={"400px"}
            height={"100%"}
          ></Chart>
        </div>
      )}
      {errorMessage != "" && (
        <div className="admin-user-countries-chart-container">
          <span className="message-error">{errorMessage}</span>
        </div>
      )}
    </div>
  );
};

export default AdminUserCountries;
