import * as React from "react";
import "leaflet/dist/leaflet.css";
import { createRoot } from "react-dom/client";
import Map from "./components/map/Map";
import "./index.css";
import { ProvideAuth } from "./hooks/use-auth";

function App() {
  return (
    <ProvideAuth>
      <Map />
    </ProvideAuth>
  );
}
const container = document.getElementById("root")!;
const root = createRoot(container);
root.render(<App />);
